<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/exam-result' }">
        <app-i18n code="entities.examResult.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.examResult.view.title"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="app-page-spinner" v-if="loading" v-loading="loading"></div>
    <div v-if="record">
      <el-container>
        <el-main id="print-area">
          <!-- Header green area -->
          <el-row class="report-header" :gutter="16">
            <el-col :lg="24" :md="24" :sm="24" class="logo-area">
              <!-- <img class="img-valign" src="/images/ihu-logo-small.svg" alt /> -->
              <span class="header-text">
                <app-i18n code="entities.examResult.scorePage.headerTitle"></app-i18n>
              </span>
              <div class="header-right-text" :class="rtlAndPadding">
                <strong>
                  <app-i18n code="entities.examResult.scorePage.scoreReportCode"></app-i18n>:
                </strong>
                <span class="card-title"> {{reportData.reportCode}} </span>
              </div>
            </el-col>
          </el-row>
          <!-- End header green area -->

          <el-row :gutter="16" class="user-area">
            <el-col :lg="{span:'4', offset:'1'}" :md="6" :sm="6" :xs="24" class="user-row">
              <el-image class="score-image" :src="reportData.userImage"></el-image>
            </el-col>
            <el-col
              :lg="{span:'12', offset:'1'}"
              :md="{span:'10', offset:'1'}"
              :sm="{span:'10', offset:'1'}"
              :xs="{span:'24', offset:'5'}"
            >
              <el-row :gutter="16" class="test-explanation">
                <el-col :lg="24" :md="24" :sm="24">
                  <br />
                  <span class="col-header-text text-line">{{record.student.fullName}}</span>
                </el-col>
                <el-col :lg="24" :md="24" :sm="24" :class="rtlAndPadding">
                  <p class="text-align-left">
                    <b class="blank-space-right">
                      <app-i18n code="entities.examResult.scorePage.testId"></app-i18n>:
                    </b>
                     <span class="card-title"> {{reportData.testID}} </span>
                  </p>
                  <p class="text-align-left">
                    <b class="blank-space-right">
                      <app-i18n code="entities.examResult.scorePage.registrationId"></app-i18n>:
                    </b>
                   <span class="card-title"> {{reportData.registrationID}} </span>
                  </p>
                </el-col>
              </el-row>
            </el-col>
            <el-col :lg="4" :md="6" :sm="6" :xs="{span:'8', offset:'9'}">
              <div class="vl"></div>
              <div class="overall-score-head">
                <app-i18n code="entities.examResult.scorePage.overallScore"></app-i18n>
              </div>
              <div class="overall-score">{{reportData.overallScore}}</div>
            </el-col>
            <div
              class="overall-score-right"
            >{{record.student.fullName + " - " + reportData.registrationID}}</div>
          </el-row>

          <!-- <el-row :gutter="16" class="blank-space"></el-row> -->

          <!-- Communicative Skills Bars Area -->
          <el-row :gutter="16">
            <el-col :span="22" :offset="1" :class="rtlAndPadding">
              <span class="col-header-text card-title">
                <app-i18n code="entities.examResult.scorePage.communicativeSkills"></app-i18n>
              </span>
              <div class="gray-line"></div>
              <el-row :gutter="16" class="blank-space"></el-row>
            </el-col>
          </el-row>
          <el-row :gutter="16">
            <el-col
              :key="index"
              v-for="(cs,index) in communicativeStatics.skills"
              :md="6"
              :sm="12"
              class="circle-scores"
            >
              <lin-progress
                type="circle"
                :percentage="record[cs]"
                :stroke-width="10"
                :color="communicativeStatics.colors[index]"
              ></lin-progress>
              <p class="com-circles">
                <app-i18n :code="'home.charts.exam.'+cs" />
              </p>
            </el-col>
          </el-row>

          <!-- End Communicative Skills Bars Area -->
          <el-row :gutter="16" class="blank-space"></el-row>

          <el-row :gutter="16">
            <el-col :span="22" :offset="1">
              <el-col :lg="24" :md="24" :sm="24" class="score-explanation">
                <div class="gray-line"></div>
                <p> <app-i18n code="entities.examResult.scorePage.descriptiontext"></app-i18n> </p>
              </el-col>
            </el-col>
          </el-row>

          <el-row :gutter="16" class="blank-space"></el-row>

          <el-row :gutter="16">
            <el-col :lg="12" :md="12" :sm="24">
              <div :class="{'text-align-right' : returnRTL}">
              <span class="col-header-text">
                <app-i18n code="entities.examResult.scorePage.skillsBreakdown"></app-i18n>
              </span>
              </div>
              <div class="gray-line"></div>
              <app-home-chart key="score-comm" :config="communicativeskills" />
              <app-home-chart key="score-enab" :config="enablingskills" />
            </el-col>
            <el-col :lg="12" :md="12" :sm="24" :class="checkRTL">
              <span class="col-header-text">
                <app-i18n code="entities.examResult.scorePage.testCentreInformation.title"></app-i18n>
              </span>
              <div class="gray-line"></div>
              <table>
                <tr>
                  <td>
                    <b>
                      <app-i18n code="entities.examResult.scorePage.testCentreInformation.testDate"></app-i18n>:
                    </b>
                  </td>
                  <td>{{reportData.testCentreInfos.testDate}}</td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <app-i18n
                        code="entities.examResult.scorePage.testCentreInformation.validUntil"
                      ></app-i18n>:
                    </b>
                  </td>
                  <td>{{reportData.testCentreInfos.validUntil}}</td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <app-i18n
                        code="entities.examResult.scorePage.testCentreInformation.issueDate"
                      ></app-i18n>:
                    </b>
                  </td>
                  <td>{{reportData.testCentreInfos.issueDate}}</td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <app-i18n
                        code="entities.examResult.scorePage.testCentreInformation.testCentreCountry"
                      ></app-i18n>:
                    </b>
                  </td>
                  <td>{{reportData.testCentreInfos.testCentreCountry}}</td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <app-i18n
                        code="entities.examResult.scorePage.testCentreInformation.testCentreId"
                      ></app-i18n>:
                    </b>
                  </td>
                  <td>{{reportData.testCentreInfos.testCentreId}}</td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <app-i18n
                        code="entities.examResult.scorePage.testCentreInformation.testCentre"
                      ></app-i18n>:
                    </b>
                  </td>
                  <td>{{reportData.testCentreInfos.testCentre}}</td>
                </tr>
              </table>
              <br />
              <span class="col-header-text">
                <app-i18n code="entities.examResult.scorePage.candidateInformation.title"></app-i18n>
              </span>
              <div class="gray-line"></div>
              <table>
                <tr>
                  <td>
                    <b>E-mail:</b>
                  </td>
                  <td>{{reportData.candidateInfos.eMail}}</td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <app-i18n
                        code="entities.examResult.scorePage.candidateInformation.dateofBirth"
                      ></app-i18n>:
                    </b>
                  </td>
                  <td>{{reportData.candidateInfos.dateOfBirth}}</td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <app-i18n
                        code="entities.examResult.scorePage.candidateInformation.countryofCitizenship"
                      ></app-i18n>:
                    </b>
                  </td>
                  <td>{{reportData.candidateInfos.countryOfCitizenship}}</td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <app-i18n
                        code="entities.examResult.scorePage.candidateInformation.countryofResidence"
                      ></app-i18n>:
                    </b>
                  </td>
                  <td>{{reportData.candidateInfos.countryOfResidence}}</td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <app-i18n code="entities.examResult.scorePage.candidateInformation.gender"></app-i18n>:
                    </b>
                  </td>
                  <td>{{reportData.candidateInfos.gender}}</td>
                </tr>
                <!--                <tr>-->
                <!--                  <td>-->
                <!--                    <b>Fist Time Test:</b>-->
                <!--                  </td>-->
                <!--                  <td>{{reportData.candidateInfos.firstTimeTest}}</td>-->
                <!--                </tr>-->
              </table>
            </el-col>
          </el-row>
          <el-row :gutter="16" class="blank-space"></el-row>
          <el-row :gutter="16" class="footer">
            <el-col :lg="24" :md="24" :sm="24"></el-col>
          </el-row>
        </el-main>
      </el-container>

      <el-tooltip content="Download" placement="top" effect="light" v-if="printButton">
        <el-button @click="getprint" class="btn-print-page">
          <i class="el-icon-fa-print"></i>
        </el-button>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import HomeChart from '@/modules/home/components/home-chart.vue';
import LinProgress from '@/modules/exam-result/components/linProgress';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import domToPdf from 'dom-to-pdf';
import { mapActions, mapGetters } from 'vuex';
import { i18n } from '@/i18n';
import AppI18n from '../../../shared/i18n/i18n';
import moment from 'moment';

export default {
  props: ['id'],
  data() {
    return {
      printButton: true
    };
  },
  async created() {
    await this.doFind(this.id);
  },
  updated() {
    var deger = document.querySelectorAll('.el-progress__text');
    deger.forEach(element => {
      // element.removeAttribute("style");
      element.style.fontSize = '26px';
      element.textContent = element.textContent.replace('%', '');
    });
  },

  components: {
    AppI18n,
    [HomeChart.name]: HomeChart,
    LinProgress
  },

  computed: {
    ...mapGetters({
      record: 'examResult/view/record',
      loading: 'examResult/view/loading',
      checkRTL: 'examResult/form/checkRTL',
      rtlAndPadding: 'examResult/form/rtlAndPadding'
    }),
    returnRTL() {
      return this.checkRTL.trim() == 'rtl' ? true : false;
    },
    reportData() {
      const examResultIdParts = this.record.id.split('-');
      const examIdParts = this.record.examId.split('-');
      let testDate = moment(this.record.examSessionDate).format('YYYY-MM-DD');
      const validUntil = moment(this.record.examSessionDate).add(5, 'years').format('YYYY-MM-DD');
      const issueDate = moment(this.record.examSessionDate).add(-5, 'days').format('YYYY-MM-DD');
      let userImage = '/images/default-user.png';
      if(this.record.student.avatars && this.record.student.avatars.length > 0){
        userImage = this.record.student.avatars[0].publicUrl;
      }
      return {
        userImage,
        reportCode: examResultIdParts[0], //"e06ba46b-bd2e-4d78-8ecd-bb77e5b4972f"
        testID: examIdParts[0],
        registrationID: examIdParts[examIdParts.length - 1],
        overallScore: this.record.aftermath,
        testCentreInfos: {
          testDate,
          validUntil,
          issueDate,
          testCentreCountry: 'Turkey',
          testCentreId: '07832683',
          testCentre: 'Ibn Haldun University'
        },
        candidateInfos: {
          dateOfBirth: '-',
          countryOfCitizenship: '-',
          countryOfResidence: '-',
          gender: '-',
          eMail: this.record.student.email
          // firstTimeTest: 'Yes'
        },
        printButton: true
      };
    },
    communicativeStatics() {
      let retVal = {};
      retVal.skills = ['reading', 'writing', 'listening', 'speaking'];
      retVal.labels = retVal.skills.map(cs => i18n('home.charts.exam.' + cs));
      retVal.colors = ['#FF6384', '#36A2EB', '#FFCE56', '#4CBA6A'];
      retVal.lastExamData = retVal.skills.map(cs => this.record[cs]);
      return retVal;
    },
    enablingStatics() {
      let retVal = {};
      retVal.skills = [
        'grammar',
        'oralFluency',
        'pronunciation',
        'spelling',
        'vocabulary',
        'writtenDiscourse'
      ];
      retVal.labels = retVal.skills.map(cs => i18n('home.charts.exam.' + cs));
      retVal.colorSame = [
        '#4CBA6A',
        '#4CBA6A',
        '#4CBA6A',
        '#4CBA6A',
        '#4CBA6A',
        '#4CBA6A'
      ]; //,'#36A2EB','#FFCE56','#4cba6a'];
      retVal.colors = [
        '#4CBA6A',
        '#F36F13',
        '#FF6384',
        '#36A2EB',
        '#FFCE56',
        '#063951'
      ]; //,'#36A2EB','#FFCE56','#4cba6a'];
      retVal.lastExamData = retVal.skills.map(cs => this.record.results[cs]);
      return retVal;
    },
    circlePlugins() {
      return {
        datalabels: {
          backgroundColor: function(context) {
            return context.dataset.backgroundColor;
          },
          anchor: 'end',
          borderColor: 'white',
          borderRadius: 25,
          borderWidth: 1,
          // color: 'white',
          color: function(context) {
            var index = context.dataIndex;
            var deger = context.dataset.backgroundColor[index];
            return deger == '#FFCE56' ? '#545454' : 'white';
          },
          display: function(context) {
            var dataset = context.dataset;
            var count = dataset.data.length;
            var value = dataset.data[context.dataIndex];
            return value > count * 1.5;
          },
          font: {
            size: 14,
            weight: 'bold'
          },
          formatter: Math.round
        }
      };
    },
    communicativeskills() {
      return {
        type: 'horizontalBar',
        title: i18n('entities.examResult.scorePage.communicativeSkills'),
        underlinetext: true,
        data: {
          labels: this.communicativeStatics.labels,
          datasets: [
            {
              backgroundColor: this.communicativeStatics.colors,
              data: this.communicativeStatics.lastExamData
            }
          ]
        },
        plugins: [ChartDataLabels],
        options: {
          plugins: this.circlePlugins,
          scales: {
            yAxes: [
              {
                barPercentage: 0.6
              }
            ],
            xAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  max: 90
                }
              }
            ]
          },
          layout: {
            padding: {
              bottom: 28,
              top: 10,
              right: 15,
              left: 35
            }
          },
          legend: { display: false }
        }
      };
    },
    enablingskills() {
      return {
        type: 'horizontalBar',
        title: i18n('entities.examResult.scorePage.enablingskills'),
        // titleScore: '',
        underlinetext: true,
        data: {
          labels: this.enablingStatics.labels,
          datasets: [
            {
              label: 'Score',
              backgroundColor: this.enablingStatics.colorSame,
              data: this.enablingStatics.lastExamData
            }
          ]
        },
        plugins: [ChartDataLabels],
        options: {
          plugins: this.circlePlugins,
          scales: {
            yAxes: [
              {
                barPercentage: 0.7,
                ticks: {
                  fontSize: 12
                }
              }
            ],
            xAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  max: 90
                }
              }
            ]
          },
          legend: { display: false },
          layout: {
            padding: {
              bottom: 20,
              top: 0,
              right: 15,
              left: -4
            }
          }
        }
      };
    }
  },

  methods: {
    ...mapActions({
      doFind: 'examResult/view/doFindScoreReport'
    }),
    getprint() {
      this.printButton = false;
      var element = document.getElementById('print-area');
      var options = {
        filename: `score-report-${this.reportData.testID}.pdf` ,
        overrideWidth: '1120'
      };
      domToPdf(element, options);
    }
  }
};
</script>
<style scoped>
.el-container {
  max-width: 1110px;
  margin: 0 auto;
}
.el-main {
  background-color: #ffffff;
  margin-top: 24px;
  margin-bottom: 0px;
  overflow: hidden;
}
.report-header {
  background-color: #7fcdcd;
  color: rgb(8, 8, 8);
  height: 100px;
  line-height: 100px;
}
.chart {
  /* background-color: white; */
  border: none;
  height: 250px;
}
.user-area {
  margin-top: 30px;
}
.blank-space {
  height: 20px;
}
.col-header-text {
  font-weight: 600;
  font-size: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
table,
th,
td {
  border-collapse: collapse;
}
th,
td {
  padding: 13px;
}
.circle-scores {
  text-align: center;
  font-size: 18px;
}
.el-header .logo-area {
  margin-top: 5px;
}
.img-valign {
  vertical-align: middle;
  margin-bottom: 0.75em;
  width: 40px;
  height: 50px;
}
.user-row {
  text-align: center;
}
.user-row img {
  max-width: 160px;
  max-height: 220px;
  border-radius: 15px;
  vertical-align: middle;
}
.vl {
  border-left: 0.5px solid gray;
  height: 65%;
  position: absolute;
  margin-left: -23px;
  top: 15px;
}

.gray-line {
  width: 100%;
  height: 1px;
  background-color: gray;
}
.overall-score-right {
  writing-mode: vertical-lr;
  position: relative;
  float: right;
  font-family: monospace;
  z-index: 999;
  font-size: 12px;
}
.overall-score-head {
  width: 110px;
  height: 30px;
  line-height: 36px;
  font-size: 14px;
  background-color: rgb(63, 63, 63);
  color: #ffffff;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  margin-top: 20px;
  text-align: center;
}
.overall-score {
  width: 110px;
  height: 80px;
  line-height: 70px;
  background-color: #ca2745;
  color: #ffffff;
  font-weight: bold;
  font-size: 46px;
  border-radius: 0% 0% 50% 50%;
  text-align: center;
}
.header-text {
  font-size: 30px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  padding-left: 10px;
}
.score-report-code {
  font-size: 16px;
  font-family: sans-serif;
}
.test-explanation {
  font-weight: 300;
}
.footer {
  background-color: #7fcdcd;
  color: rgb(8, 8, 8);
  height: 45px;
  margin-top: 40px;
  border-bottom: 1px solid gray;
}
.score-explanation {
  color: #3a3a3a;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
}
.header-right-text {
  float: right;
  padding-right: 20px;
}
.text-underline {
  text-decoration: none;
}
.text-underline:after {
  /* position: absolute; */
  content: '';
  height: 2px;
  bottom: -4px;
  left: 0;
  right: 0;
  width: 50%;
  background: #4cba6a;
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.text-underline:hover:after {
  width: 100%;
  background: red;
}

.btn-print-page {
  position: fixed;
  bottom: 50px;
  right: 23px;
  z-index: 99;
  font-size: 18px;
  border: 0.9px solid grey;
  outline: none;
  background-color: white;
  cursor: pointer;
  padding: 12px 16px;
  border-radius: 5px;
  transition: all 0.8s;
  transition-timing-function: cubic-bezier(0.5, 3, 0, 1);
}

.btn-print-page:hover {
  background-color: whitesmoke;
  transform: scale(1.1, 1.1);
}

.text-line {
  padding-bottom: 4px;
  border-bottom: 1px solid #4cba6a;
}

.blank-space-right {
  padding-right: 20px;
}

@media only screen and (min-width: 1900px) { 
  .overall-score-right {
    font-size: 7px;
  }
}

@media only screen and (max-width: 1200px) {
  .header-text {
    font-size: 27px;
    padding-left: 10px;
  }
  .vl {
    display: none;
  }
  .overall-score-right {
    font-size: 12px;
  }
}

@media only screen and (max-width: 900px) {
  .header-right-text {
    display: none;
  }
  .logo-area {
    text-align: center;
  }
  .overall-score-right {
    font-size: 8px;
  }
}

@media only screen and (max-width: 600px) {
  .header-text {
    font-size: 20px;
    padding-left: 10px;
  }
  .vl {
    display: none;
  }
  .score-report-code {
    font-size: 20px;
  }
}
@media only screen and (max-width: 500px) {
  .header-text {
    font-size: 25px;
    padding-left: 10px;
  }
}

.com-circles {
  margin-bottom: 0;
  margin-top: 0.6rem;
}

.score-image {
  border-radius: 50%;
}

.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
  padding-right: 3px;
}
</style>